import { post } from '@/utils/request';

// 优惠券列表统计接口

const  getCouponList=(data)=>{
    return post({
        url:'/store/coupon.Record/list',
        data
    })
}


//作废优惠券
const watesCoupon=(data)=>{
    return post({
        url:"/store/coupon.Record/invalid",
        data
    })
}


export  {
    getCouponList,
    watesCoupon
}