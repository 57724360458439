<template>
  <div id="box">
      <div class="total">
        <div class="total-right">
          <div class="total-right-item">
         发放总数:<span>{{ receive_total }}</span>
          </div>
          <div class="total-right-item">
            未使用:<span>{{ not_use_total }}</span>
          </div>
          <div class="total-right-item">
            已使用:<span>{{ use_total }}</span>
          </div>
          <div class="total-right-item">
           已过期: <span>{{ expired_total }}</span>
          </div>
        </div>
      </div>
    <div class="main">
      <div class="content" id="content" style="padding:10px 0">
        <el-form :inline="true" label-width="100px">
          <el-form-item prop="search" label="搜索" class="search">
            <el-input v-model="form.search" clearable placeholder="输入手机号/领取人/发券人/券编号/券名称查询"></el-input>
          </el-form-item>

          <el-form-item label="使用门店">
            <el-select v-model="form.hierarchy_id" clearable>
              <el-option :key="index" :value="item.value" :label="item.label" v-for="(item, index) in shopList">
                {{ item.label }}
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span slot="label">券种类</span>
            <el-select v-model="form.category_seq" clearable>
              <el-option :key="index" v-for="(item, index) in canalnList" :value="item.value" :label="item.label">{{
                  item.label
              }}</el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span slot="label">使用状态</span>
            <el-select v-model="form.use_status" clearable>
              <el-option :value="''" label="全部">全部</el-option>
              <el-option :value="10" label="未使用">未使用</el-option>
              <el-option :value="20" label="已使用">已使用</el-option>
              <el-option :value="100" label="已过期">已过期</el-option>
              <el-option :value="101" label="已转赠">已转赠</el-option>
              <el-option :value="30" label="待转让不可使用">待转让不可使用</el-option>
              <el-option :value="40" label="商家销毁">商家销毁</el-option>

            </el-select>
          </el-form-item>

          <el-form-item>
            <span slot="label">优惠类型</span>
            <el-select v-model="form.type_seq" clearable>
              <el-option :value="item.value" :label="item.label" :key="index" v-for="(item, index) in couponType">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span slot="label">获取渠道</span>
            <el-select v-model="form.give_channel_seq" clearable>
              <el-option :value="item.value" :label="item.label" :key="index" v-for="(item, index) in preferenceList">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery"> 查询</el-button>
            <el-button type="default" @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="table">
        <el-table :data="list" stripe :height="height">
          <el-table-column label="序号" type="index" width="50" fixed="left"></el-table-column>

          <el-table-column label="券编号" prop="coupon_sn" width="150" fixed="left"></el-table-column>
          <el-table-column label="券名称" prop="coupon_name" width="180"></el-table-column>
          <el-table-column label="状态" prop="use_status" align="center" :formatter="getUseStateText"
            width="150"></el-table-column>
          <el-table-column label="券类型" prop="category_seq" width="100">
            <template slot-scope="scope">
              {{ scope.row.category_seq == '10' ? '通用' : '人情' }}
            </template>
          </el-table-column>
          <el-table-column label="优惠类型" prop="type_seq" with="150" :formatter="getTypeSeqText"></el-table-column>
          <el-table-column width="80" label="券面额" prop="type_data"></el-table-column>
          <el-table-column label="领取人" prop="client_user_master_username" width="100"></el-table-column>
          <el-table-column label="手机号" prop="client_user_master_mobile" width="120"></el-table-column>
          <el-table-column label="获得渠道" prop="give_channel_seq" width="100" :formatter="getChangeText">
          </el-table-column>
          <el-table-column label="获得时间" prop="create_time" width="180"></el-table-column>
          <el-table-column label="到期时间" prop="use_end_time" width="180"></el-table-column>
          <el-table-column label="发券人" prop="store_username"></el-table-column>
          <!-- <el-table-column label="使用渠道" prop=""></el-table-column> -->
          <el-table-column label="使用门店" width="120" prop="use_hierarchy_id" :formatter="getHierarchyId">
          </el-table-column>
          <el-table-column width="150" label="使用订单号" prop="order_number"></el-table-column>
          <el-table-column width="80" label="实际支付金额(元)" prop="paid_in_amount"></el-table-column>
          <el-table-column width="80" label="券优惠金额(元)" prop="discounts_amount"></el-table-column>
          <el-table-column label="使用时间" prop="use_time" width="180"> </el-table-column>
           <el-table-column label="赠送人" prop="give_client_user_master_username"></el-table-column>
          <el-table-column width="120" label="赠送人手机号" prop="give_client_user_master_mobile"></el-table-column>
          <el-table-column label="操作" fixed="right" width="120">
              <template slot-scope="scope" v-if="scope.row.show_status==10||scope.row.show_status==30||scope.row.show_status==101">
                <el-link type="danger" @click="()=>handleWaste(scope.row)">作废优惠券</el-link>
              </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination background :current-page="listQuery.page" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :page-sizes="[15, 20, 50, 100, 200, 400]"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getCouponList,watesCoupon } from "@/api/marketing/couponCount/index";
import { getMerchantList } from "@/api/index";

export default {
  data() {
    return {
      total: 0,
      listQuery: {
        page: 1,
        limit: 15
      },

      height: 50,

      not_use_total: 0,
      use_total: 0,
      expired_total: 3,
      receive_total: 3,

      form: {
        hierarchy_id: [],
        type_seq: "",
        use_status: "",
        give_channel_seq: "",
        category_seq: ""
      },

      list: [],

      shopList: [],

      saleList: [],

      canalnList: [
        {
          label: "通用券",
          value: 10
        },
        {
          label: "人情券",
          value: 20
        }
      ],

      couponType: [
        // 优惠类型 10：指定金额 20：指定折扣 30：随机金额 40 礼品
        {
          label: "指定金额",
          value: 10
        },
        {
          label: "指定折扣",
          value: 20
        },
        {
          label: "随机金额 ",
          value: 30
        },

        {
          label: "礼品",
          value: 40
        }
      ],
      // 获得方式 10：手动领取 20：首次关注赠送 30：注册赠送 40 消费赠送 50 生日赠送 60 纪念日赠送 70 指定卷包 80 购买

      preferenceList: [
        {
          label: "手动领取",
          value: 10
        },
        {
          label: "首次关注赠送",
          value: 20
        },
        {
          label: "注册赠送",
          value: 30
        },
        {
          label: "消费赠送",
          value: 40
        },
        {
          label: "生日赠送",
          value: 50
        },
        {
          label: "纪念日赠送",
          value: 60
        },
        {
          label: "指定卷包",
          value: 70
        },
        {
          label: "系统赠送",
          value: 80
        }
      ],
      useStatus: [{
        label: '未使用',
        value: 10
      }, { label: "已使用", value: 20 }, { label: '待转让不可使用', value: 30 }, { label: '商家销毁', value: 40 }],

      loading3: false,
      loading: false
    };
  },

  created() {
    this.getShopsList();
    // this.getList();
  },

  mounted() {
      let sn=this.$route.params.sn;
    if(sn){
      this.$set(this.form,'search',sn);
    }
    this.getList()
    this.$nextTick(() => {
      this.getTableHeight();
    })
  },

  activated() {
    let sn=this.$route.params.sn;
    if(sn){
      this.$set(this.form,'search',sn);
      this.getList()
    }
    this.bindEvent();
  },

  deactivated() {
    this.deBindEvent();
  },

  methods: {
    getTableHeight() {
      let documentHeight = document.documentElement.clientHeight;
      let cardHeight = document.querySelector(".total").clientHeight;
      let contentHeight = document.querySelector('#content').clientHeight;
      let paginaHeight = document.querySelector('.pagina').clientHeight;
      this.height = documentHeight - cardHeight - contentHeight - paginaHeight - 65 - 20 - 20 - 20;
    },

    bindEvent() {
      window.addEventListener('resize', this.getTableHeight, false);
    },

    deBindEvent() {
      window.removeEventListener('resize', this.getTableHeight, false)
    },

    getShopsList() {
      getMerchantList({ ...this.form, ...this.listQuery }).then(res => {
        if (res.code == 1) {
          this.shopList = res.data.map(item => {
            return {
              label: item.hierarchy_name,
              value: item.hierarchy_id
            };
          });
        }
      });
    },

    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      getCouponList({ ...this.listQuery, ...this.form })
        .then(res => {
          if (res.code == 1) {
            this.loading = false;
            this.list = res.data.list;
            this.total = res.data.total;
            this.not_use_total = res.data.not_use_total;
            this.use_total = res.data.use_total;
            this.expired_total = res.data.expired_total;
            this.receive_total = res.data.receive_total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },

    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },

    handleReset() {
      let formDefault = this.$options.data().form;
      this.form = { ...formDefault };
      this.listQuery.page = 1;
      this.getList();
    },

    handleQuery() {
      this.listQuery.page = 1;
      this.getList();
    },

    handleExport() {
      this.loading3 = true;
      getCouponList({
        limit: this.listQuery.limit,
        page: this.listQuery.page,
        export_url: 1
      })
        .then(res => {
          this.loading3 = false;
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `${moment().format("yyyy-MM-DD")}优惠券统计.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = true;
        });
    },

    handleWaste(row){
   this.$confirm('此操作将作废当条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res=>watesCoupon({coupon_sn:row.coupon_sn,client_user_master_id:row.client_user_master_id})).then(res=>{
         if(res.code==1){
            this.listQuery.page=1;
            this.$message.success(res.msg)
            this.getList();
          }
      }).catch(err=>{
          this.$message({
          type: 'info',
          message: '已取消作废'
        });
      })
    }
    ,
    getTypeSeqText(row) {
      if (row.type_seq == "") { return '' }
      return this.couponType.find(item => item.value == row.type_seq)?.label;
    },

    getChangeText(row) {
      if (!row.give_channel_seq) { return '' }
      return this.preferenceList.find(item => item.value == row.give_channel_seq)?.label
    },

    getUseStateText(row) {
      if (!row.use_status) { return '全部' }
      return this.useStatus.find(item => item.value == row.use_status)?.label
    },

    getHierarchyId(row) {
      if (!row.hierarchy_id) { return }
      return this.shopList.find(item => item.value == row.hierarchy_id)?.label;
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  display: flex;
  justify-content: space-evenly;
}

.flex {
  display: flex;
  align-items: center;
}

.direction-column {
  flex-direction: column;
  justify-content: center;
}

.card-item {
  width: 200px;
  height: 100px;
  border: 1px solid #ccc;
}

.main {
  padding: 10px 0 0 0;

}

.search .el-form-item__content .el-input {
  width: 360px !important;
}


.total {
  // display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  // margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;

  &-left {
    // flex-shrink: 0;
  }

  &-right {
    display: flex;
    flex: 1;

    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 25%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;

      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
</style>
